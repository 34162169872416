/** @jsx jsx */
import * as React from 'react';
import { jsx, Box, Text } from 'theme-ui';
import { Link } from 'gatsby';
import ItemTags from './item-tags';
import { yearMonthDayShortFormatter } from '../../../utils/date';

type BlogListItemProps = {
  post: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  };
  showTags?: boolean;
};

const BlogListItem = ({ post, showTags = true }: BlogListItemProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }} mb={4}>
    <Link
      to={post.slug}
      sx={t => ({ ...t.styles?.a, fontSize: [1, 2, 3], color: `text` })}
    >
      {post.title}
    </Link>
    <Text
      sx={{
        color: `secondary`,
        mt: 1,
        a: { color: `secondary` },
        fontSize: [1, 1, 2],
      }}
    >
      <time>{yearMonthDayShortFormatter(post.date)}</time>
      {post.tags && showTags && (
        <React.Fragment>
          {` — `}
          <ItemTags tags={post.tags} />
        </React.Fragment>
      )}
    </Text>
  </Box>
);

export default BlogListItem;
