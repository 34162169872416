/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import BlogListItem from './blog-list-item';

type ListingProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  }[];
  className?: string;
  showTags?: boolean;
};

const Listing = ({ posts, className = ``, showTags = true }: ListingProps) => (
  <section className={className}>
    <Box sx={{ mb: [5, 6, 7] }}>
      {posts.map(post => (
        <BlogListItem key={post.slug} post={post} showTags={showTags} />
      ))}
    </Box>
  </section>
);

export default Listing;
